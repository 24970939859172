
import Vue from 'vue';
import router from '@/core/router';
import Component from 'vue-class-component';
import LanguageSwitcher from '@/general/infrastructure/LanguageSwitcher.vue';
import { getToken, requestGet, requestPut } from '@/core/plugins/FunctionUtil';
import { showAlert } from '@/core/plugins/Notifications';

@Component({
  name: 'ForgetPassword',
  components: {
    LanguageSwitcher
  }
})
export default class AppForgetPassword extends Vue {
  //Data
  forgetEmail = '';
  forgetPassword = '';
  forgetRePassword = '';
  disabledForm = true;
  forgetCodeFirst = '';
  forgetCodeTwo = '';
  forgetCodeThree = '';
  //Refs
  $refs!: {
    forgetEmail: HTMLInputElement;
  };
  //Hook
  created() {
    this.$nextTick(() => this.$refs.forgetEmail.focus());
  }
  //Methods
  public getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public getSecurityCodeCustomer(email: string) {
    const response = requestGet('user/getSecurityCode?email=' + email);
    response
      .then((response: any) => {
        if (response.status >= 200 && response.status <= 299) {
          this.disabledForm = false;
          showAlert(
            'success',
            this.$i18n.t('general.code') +
              ' ' +
              this.$i18n.t('general.verification') +
              '\n' +
              this.$i18n.t('general.sentt') +
              ' ' +
              this.$i18n.t('general.to') +
              ' ' +
              this.forgetEmail
          );
        } else {
          return false;
        }
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  public updatePasswordUser() {
    const payload: any = {
      password: this.forgetPassword,
      securityCode: this.forgetCodeFirst + this.forgetCodeTwo + this.forgetCodeThree
    };
    getToken().then((res: any) => {
      const response = requestPut(
        'user/updatePassword?' + `password=${payload.password}&securityCode=${payload.securityCode}`,
        '',
        res
      );
      response
        .then((response: any) => {
          if (response.status >= 200 && response.status <= 299) {
            router.push({ name: 'Login' });
            return true;
          } else {
            return false;
          }
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
  }
}
